<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-2">Rango de fechas</h6>
        <!--Dates-->
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Desde*</label>
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Hasta*</label>
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
        <!--Clients-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona cliente</h6>
              <v-select :options="clients" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="client" class="mb-4 md:mb-0" name="client" id="client"/>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <new :invoices="invoices" @add="addDataSideBar" :isSidebarActive="showNewSidebar"
         @closeSidebar="showNewSidebar= false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar (Formato para Excel)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.lowCommunicationNubefact"
                  @click="selected.lowCommunicationNubefact ? openLink(selected.lowCommunicationNubefact.enlace_del_pdf): ''">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver comunicación de baja</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="selected.nubefact ? openLink(selected.nubefact.enlace_del_pdf): ''">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver documento (O)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'send' || selected.status === 'rejected'"
                  @click="openPrintDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="PrinterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Imprimir (I)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'send'"
                  @click="openCancelDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="XIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Anular (A)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'hold'"
                  @click="openResendDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="UploadIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Reenviar (R)</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="sequence">Nro. Doc.</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th sort-key="registrationDate">Fecha de registro</vs-th>
        <vs-th sort-key="cot">Comprobante</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.client.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.code }}</p>
          </vs-td>

          <vs-td>
            <p>
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total)
              }}
            </p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
              {{ getStateText(tr.status) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <vs-chip @click.native="openLink(tr.invoice.link)" color="dark" class="product-order-status cursor-pointer">
              {{ tr.invoice.code }}
            </vs-chip>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon @click="openLink(tr.nubefact.enlace_del_pdf)"
                          v-if="tr.status === 'send' || tr.status === 'rejected'" title="Link"
                          icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="openCancelDialog(tr)" v-if="tr.status === 'send'" title="Anular"
                          icon="XIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>


            <feather-icon @click="openResendDialog(tr)" v-if="tr.status === 'hold'" title="Reenviar"
                          icon="UploadIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>

            <feather-icon @click="openPrintDialog(tr)" v-if="tr.status === 'send'" title="Imprimir"
                          icon="PrinterIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'

import { auth, db, Timestamp } from '@/firebase/firebaseConfig'
import openLink from '@/mixins/link'
import New from '@/views/credits/New'
//import Update from '@/views/purchases/Update'
import axios from 'axios'
import { es } from 'vuejs-datepicker/dist/locale'

export default {
  name: 'CreditsList',
  components: {
    vSelect,
    New,
    //Update,
    Datepicker
  },
  mixins: [openLink],
  data () {
    return {
      selected: {},
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      invoices: [],
      credits: [],
      clients: [],
      initProgress: false,

      // Data Sidebar
      showNewSidebar: false,
      sidebarData: {},
      showCancel: false,
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      selectedDocument: {},
      // Filter
      startDate: null,
      endDate: null,
      es: es,
      client: null,
      evt: null
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadCredits()
      await this.loadInvoices()
      await this.loadClients()
      // Set filter las 30 days
      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.endDate = new Date()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
    // Listen keyboard
    // this.evt = window.document
    // this.evt.addEventListener('keyup', this.listenKeyboard)
  },
  destroyed () {
    this.evt.removeEventListener('keyup', this.listenKeyboard)
  },
  methods: {
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const _ = require('lodash')
        const list = _.cloneDeep(this.list)
        list.forEach((o => {
          o.client = o.client.businessName
          o.currency = o.currency.value
          o.createdAt = o.createdAt.getFullYear() + '-' + (o.createdAt.getMonth() + 1) + '-' + o.createdAt.getDate()
          o.user = o.user.displayName
          o.state = this.getStateText(o.status)
        }))
        const headerVal = ['client', 'code', 'currency', 'total', 'createdAt', 'user', 'state']
        const headerTitle = ['Cliente', 'Nro. Comprobante', 'Moneda', 'Total', 'Fecha de registro', 'Usuario', 'Estado']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'send') return 'success'
      if (status === 'rejected') return 'danger'
      return 'success'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'send') return 'Enviada'
      if (value === 'rejected') return 'Anulada'
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async loadCredits () {
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('credits')
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      } else {
        querySnap = await db.collection('credits')
            .where('user.uid', '==', auth.currentUser.uid)
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      }
      querySnap.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate()
        }
        this.list.push(obj)
      })
    },
    /**
     * Get sales of firestore
     * @returns {Promise<void>}
     */
    async loadInvoices () {
      this.sales = []
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('sales')
            .where('state', '==', true)
            .where('status', '==', 'send')
            .orderBy('createdAt', 'desc')
            .limit(1000).get()
      } else {
        querySnap = await db.collection('sales')
            .where('user.uid', '==', auth.currentUser.uid)
            .where('state', '==', true)
            .where('status', '==', 'send')
            .orderBy('createdAt', 'desc')
            .limit(1000).get()
      }

      querySnap.forEach((doc) => {
        let obj = {
          id: doc.id,
          code: doc.data().code,
          client: doc.data().client,
          currency: doc.data().currency,
          total: doc.data().total,
          igv: doc.data().igv,
          subTotal: doc.data().subTotal,
          products: doc.data().products,
          createdAt: doc.data().createdAt.toDate(),
          roundingType: doc.data().roundingType,
          status: doc.data().status,
          igvType: doc.data().igvType,
          sequence: doc.data().sequence,
          serie: doc.data().serie,
          paymentMethod: doc.data().paymentMethod,
          regimeType: doc.data().regimeType,
          link: doc.data().nubefact.enlace_del_pdf
        }
        this.invoices.push(obj)
      })
    },
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async loadClients () {
      this.clients = []
      const querySnap = await db.collection('clients')
          .where('state', '==', true)
          .orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo,
          email: doc.data().email
        }
        this.clients.push(client)
      })
    },
    /**
     * Low communication invoice confirm
     * @param tr
     */
    openCancelDialog (tr) {
      this.selectedDocument = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas dar de baja la nota de crédito "${tr.code}"?`,
        accept: this.acceptDialogLowCommunication,
        cancel: '',
        parameters: [tr],
        acceptText: 'Dar de baja',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Low communication send nubefact
     * @returns {Promise<void>}
     */
    async acceptDialogLowCommunication () {
      try {
        this.initProgress = true
        const response = await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-cancelInvoice', {
          document: {
            operacion: 'generar_anulacion',
            tipo_de_comprobante: 3,
            serie: this.selectedDocument.serie,
            numero: this.selectedDocument.sequence,
            motivo: 'BAJA POR ERROR DEL SISTEMA'
          }
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        // Update status and response to nubefact
        await db.collection('credits').doc(this.selectedDocument.id).update({
          lowCommunicationNubefact: {
            ...response.data.nubefact
          },
          status: 'rejected'
        })
        const objInvoice = this.list.find((o) => o.id === this.selectedDocument.id)
        objInvoice.status = 'rejected'
        objInvoice.lowCommunicationNubefact = {
          ...response.data.nubefact
        }
        this.selectedDocument = {}
        // Open PDF in browser
        window.open(response.data.nubefact.enlace_del_pdf, 'blank')
        this.$vs.notify({
          color: 'success',
          title: 'Nota de crédito',
          text: 'Comunicación de baja realizada.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Resend invoice to nubefact confirm
     * @param tr
     */
    openResendDialog (tr) {
      this.selectedDocument = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas enviar a Nubefact la nota de crédito "${tr.code}"?`,
        accept: this.sendNubefact,
        cancel: '',
        parameters: [tr],
        acceptText: 'Enviar',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Send to Eprint
     * @param tr
     */
    openPrintDialog (tr) {
      this.selectedInvoice = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas imprimir remotamente la nota de crédito "${tr.code}"?`,
        accept: this.acceptDialogPrint,
        cancel: '',
        parameters: [tr],
        acceptText: 'Imprimir',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Eprint
     * @returns {Promise<void>}
     */
    async acceptDialogPrint () {
      try {
        this.initProgress = true
        await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/print-sendToPrint', {
          url: this.selectedInvoice.nubefact.enlace_del_pdf
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        this.selectedInvoice = {}
        this.$vs.notify({
          color: 'success',
          title: 'Nota de crédito',
          text: 'Nota de crédito enviada a impresión.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Send invoice to Nubefact
     */
    async sendNubefact () {
      try {
        this.initProgress = true
        let sequence = this.selectedDocument.sequence
        let total = this.selectedDocument.total
        let subTotal = this.selectedDocument.subTotal
        let igv = this.selectedDocument.igv
        let serie = this.selectedDocument.serie
        const items = []
        this.selectedDocument.products.forEach((p) => {
          items.push(
              {
                'unidad_de_medida': 'NIU',
                'codigo': p.numberPart,
                'codigo_producto_sunat': '32130000',
                'descripcion': p.description,
                'cantidad': p.quantity,
                'valor_unitario': p.price,
                'precio_unitario': parseFloat((p.price * 1.18).toFixed(this.selectedDocument.roundingType)),
                'descuento': '',
                'subtotal': parseFloat((p.price * p.quantity).toFixed(this.selectedDocument.roundingType)),
                'tipo_de_igv': 1,
                'igv': parseFloat((p.price * p.quantity * 1.18).toFixed(this.selectedDocument.roundingType)) - parseFloat((p.price * p.quantity).toFixed(this.selectedDocument.roundingType)),
                'total': parseFloat((p.price * p.quantity * 1.18).toFixed(this.selectedDocument.roundingType)),
                'anticipo_regularizacion': false,
                'anticipo_documento_serie': '',
                'anticipo_documento_numero': ''
              }
          )
        })
        // Split emails
        const emails = this.document.client.email !== '_' ? this.document.client.email.split(',') : []
        // End
        const document = {
          'operacion': 'generar_comprobante',
          'tipo_de_nota_de_credito': this.selectedDocument.type.id,
          'documento_que_se_modifica_tipo': this.selectedDocument.client.typeDocument === 'RUC' ? 1 : 2,
          'documento_que_se_modifica_serie': this.selectedDocument.invoice.serie,
          'documento_que_se_modifica_numero': this.selectedDocument.invoice.sequence,
          'tipo_de_comprobante': 3,
          'serie': serie,
          'numero': sequence,
          'sunat_transaction': 1,
          'cliente_tipo_de_documento': this.selectedDocument.client.typeDocument === 'RUC' ? '6' : '1',
          'cliente_numero_de_documento': this.selectedDocument.client.document + '',
          'cliente_denominacion': this.selectedDocument.client.businessName,
          'cliente_direccion': this.selectedDocument.client.address,
          'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
          'moneda': this.selectedDocument.currency.id,
          'porcentaje_de_igv': 18,
          'total_gravada': subTotal,
          'total_igv': igv,
          'total': total,
          'enviar_automaticamente_a_la_sunat': true,
          'enviar_automaticamente_al_cliente': true,
          'formato_de_pdf': '',
          'tipo_de_cambio': 3.9,
          'cliente_email': emails.length === 1 ? emails[0].trim() : '',
          'cliente_email_1': emails.length === 2 ? emails[1].trim() : '',
          'cliente_email_2': emails.length === 3 ? emails[2].trim() : ''
        }
        const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendInvoices', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        // Update invoice
        await db.collection('credits').doc(this.selectedDocument.id).update({
          nubefact: {
            ...response.data.nubefact
          },
          status: 'send'
        })
        this.selectedDocument.status = 'send'
        this.selectedDocument.nubefact = {
          ...response.data.nubefact
        }
        // End
        this.$vs.notify({
          color: 'success',
          title: 'Nota de crédito',
          text: 'Nota de crédito enviada correctamente.'
        })
        // Open PDF in browser
        window.open(response.data.nubefact.enlace_del_pdf, 'blank')
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
        return -1
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.client = null
      await this.loadCredits()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end date
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}

          if (this.client) {
            if (this.$acl.check('admin')) {
              querySnapshot = await db.collection('credits')
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
            } else {
              querySnapshot = await db.collection('credits')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
            }
          } else {
            if (this.$acl.check('admin')) {
              querySnapshot = await db.collection('credits')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
            } else {
              querySnapshot = await db.collection('credits')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
            }
          }

          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            }
            this.list.push(obj)
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Listen keyboard
     */
    listenKeyboard (ev) {
      if (this.selected && !this.showPaymentsSidebar && !this.showNewSidebarWC && !this.showNewSidebar && !this.activePrompt) {
        if (ev.keyCode === 79) { // Open Link (O)
          this.openLink(this.selected.nubefact.enlace_del_pdf)
        } else if (ev.keyCode === 73) { // Print (I)
          this.openPrintDialog(this.selected) // Cancel (A)
        } else if (ev.keyCode === 65 && this.selected.status === 'send') {
          this.openCancelDialog(this.selected)
        } else if (ev.keyCode === 82 && this.selected.status === 'hold') { // Resend (R)
          this.openResendDialog(this.selected)
        }
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.product-name {
  max-width: 23rem;
}
</style>
